import {ArrowRight, Package, Zap, Cpu} from 'react-feather';
import {Link} from 'gatsby';
import React from 'react';
import {trackCustomEvent} from 'gatsby-plugin-google-analytics';
import './function.css';

import {DesignedForDevelopers} from '../components/DesignedForDevelopers';
import Button from '../components/Button';
import CTA from '../components/CTA';
import Layout from '../components/layout';
import SEO from '../components/seo';

export default () => {
  return (
    <Layout spacingTop={false}>
      <SEO title="Serverless function fastest - Fleet" />
      <section className="relative px-6 lg:px-12">
        <div className="container py-16">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
            <div className="flex items-center justify-center">
              <div>
                <p className="font-semibold text-gray-light leading-tight mb-3">
                  BETA
                </p>
                <div className="flex items-center">
                  <img
                    className="select-none w-12 h-12"
                    alt="Fleet Function"
                    src="/images/logo-fleet-function.svg"
                  />
                  <h1 className="ml-3 text-3xl font-semibold">
                    Fleet <span className="font-normal">Function</span>
                  </h1>
                </div>
                <p className="text-lg mt-6 mb-10 leading-normal text-gray">
                  Fleet Function is a next generation of serverless function for
                  Node.js.
                </p>
                <Button
                  component="a"
                  className="sm:mr-4"
                  size="lg"
                  href="https://console.fleetfn.com"
                  onClick={() => {
                    trackCustomEvent({
                      category: 'Join the Private Beta now [Function Page]',
                      action: 'Click',
                    });
                  }}
                >
                  Join the Private Beta now
                </Button>
                <Button
                  component={Link}
                  className="sm:mt-0 mt-4"
                  size="lg"
                  variant="primary-light"
                  to="/contact"
                >
                  Contact Sales
                </Button>
              </div>
            </div>
            <div className="">
              <div className="flex items-center justify-center">
                <img
                  className="select-none sm:max-w-lg"
                  alt="Fleet Function, fastest and safely"
                  src="/images/fleet-function-run.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-16 lg:mt-48 mb-16 sm:mb-40 px-6 sm:px-0">
        <div className="container">
          <div className="text-center">
            <h2 className="font-bold text-3xl sm:text-4xl mb-8">
              Serverless functions for fast and critical services for Node.js
            </h2>
            <p className="font-medium text-gray text-xl max-w-3xl inline-block eading-relaxed">
              Fleet is a Function as a Service technology that solves problems
              known as cold starting, allows you to build fast services that are
              critical in response time.
            </p>
          </div>
          <div className="relative mt-32">
            <div className="invisible sm:visible bg-timeline" />
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5 sm:pt-12">
              <div className="flex flex-col items-center sm:mb-0 mb-12">
                <h3 className="font-medium text-2xl">Functions over VM</h3>
                <p className="mt-4 text-lg">Each function per VM</p>
                <img
                  className="mt-16 select-none"
                  alt="Each function per VM"
                  src="/images/function-over-vm.svg"
                />
              </div>
              <div className="flex flex-col items-center">
                <h3 className="font-medium text-2xl">
                  Functions over Isolates
                </h3>
                <p className="mt-4 text-lg">
                  Hundreds of isolated functions per process
                </p>
                <img
                  className="mt-16 select-none"
                  alt="Hundreds of isolated functions per process"
                  src="/images/functions-over-isolates.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white-gray mt-24 sm:mt-40 px-6 sm:px-16 py-24">
        <div className="container relative">
          <h2 className="text-2xl font-semibold">How it works</h2>
          <p className="text-lg leading-normal mt-6">
            Fleet Function is a serverless function technology, which
            automatically scales its functions to 0 and N according to the
            number of requests that your function receives, runs in an isolated
            environment in Node.js that eliminates cold starting, you can
            control the asynchronous limit of function to adjust the rate that a
            function can handle requests at a time as well as the concurrency
            limit.
          </p>
          <ul className="mt-24 grid sm:grid-cols-3 grid-cols-1 sm:gap-32 gap-16">
            <li className="flex flex-col sm:flex-row">
              <div className="flex-shrink-0 box bg-black flex items-center justify-center">
                <Cpu size={24} color="#fff" />
              </div>
              <div className="sm:ml-8 sm:mt-0 mt-8">
                <h3 className="mb-2 text-lg font-semibold">V8 Isolates</h3>
                <p className="text-base">
                  Node.js uses the <a href="https://v8.dev/">V8 engine</a> under
                  the hood to interpret and execute JavaScript code. V8 has a
                  concept of isolates that allows JavaScript code to run safely
                  in browsers.
                </p>
              </div>
            </li>
            <li className="flex flex-col sm:flex-row">
              <div className="flex-shrink-0 box bg-black flex items-center justify-center">
                <Package size={24} color="#fff" />
              </div>
              <div className="sm:ml-8 sm:mt-0 mt-8">
                <h3 className="mb-2 text-lg font-semibold">
                  Use Isolates to perform functions
                </h3>
                <p className="text-base">
                  Fleet uses isolates to perform thousands of functions in a
                  single but safe process with isolated memory.
                </p>
              </div>
            </li>
            <li className="flex flex-col sm:flex-row">
              <div className="flex-shrink-0 box bg-black flex items-center justify-center">
                <Zap size={24} color="#fff" />
              </div>
              <div className="sm:ml-8 sm:mt-0 mt-8">
                <h3 className="mb-2 text-lg font-semibold">
                  Invoke functions fastest
                </h3>
                <p className="text-base">
                  Fleet can provision functions much more quickly almost
                  eliminating cold starts and isolates have less memory
                  consumption.
                </p>
              </div>
            </li>
          </ul>
          <Link
            className="flex flex-row items-center mt-16"
            to="/docs/how-it-works.html"
          >
            <p className="font-medium">
              Learn more about how our technology works
            </p>
            <ArrowRight
              className="ml-2"
              size={20}
              color="#000"
              strokeWidth={2}
            />
          </Link>
        </div>
      </section>
      <DesignedForDevelopers />
      <section className="mt-16 lg:mt-48 sm:mb-40 mb-16 px-6">
        <div className="container">
          <div className="text-center">
            <h2 className="font-bold text-4xl mb-8">
              What can you do with Fleet Function?
            </h2>
            <p className="text-xl max-w-3xl inline-block eading-relaxed">
              Fleet functions allow your company to scale quickly and not worry
              about infrastructure or cold start problems like other serverless
              providers.
            </p>
          </div>
          <ul className="mt-32 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-16">
            <li className="flex flex-col">
              <div className="rounded-md bg-black h-32 flex items-center justify-center">
                <img
                  className="select-none max-w-xl"
                  alt="JAMstack"
                  src="/images/JAMstack.svg"
                />
              </div>
              <div className="mt-6">
                <h3 className="mb-2 text-xl font-semibold">JAMstack</h3>
                <p className="text-base text-gray">
                  Enrich the JAMstack ecosystem using functions
                </p>
              </div>
            </li>
            <li className="flex flex-col">
              <div className="rounded-md bg-black h-32 flex items-center justify-center">
                <img
                  className="select-none max-w-xl"
                  alt="Microservices"
                  src="/images/microservices.svg"
                />
              </div>
              <div className="mt-6">
                <h3 className="mb-2 text-xl font-semibold">Microservices</h3>
                <p className="text-base text-gray">
                  Build your service fleet on serverless functions
                </p>
              </div>
            </li>
            <li className="flex flex-col">
              <div className="rounded-md bg-black h-32 flex items-center justify-center">
                <img
                  className="select-none max-w-xl"
                  alt="Micro APIs"
                  src="/images/apis.svg"
                />
              </div>
              <div className="mt-6">
                <h3 className="mb-2 text-xl font-semibold">APIs</h3>
                <p className="text-base text-gray">
                  Build your product's APIs on serverless functions
                </p>
              </div>
            </li>
            <li className="flex flex-col">
              <div className="rounded-md bg-black h-32 flex items-center justify-center">
                <img
                  className="select-none max-w-xl"
                  alt="Data Processing"
                  src="/images/tasks.svg"
                />
              </div>
              <div className="mt-6">
                <h3 className="mb-2 text-xl font-semibold">Tasks</h3>
                <p className="text-base text-gray">
                  Orchestrate background functions for data processing
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <CTA />
    </Layout>
  );
};
